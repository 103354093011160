import React, { useState } from "react";
import axios from 'axios';
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    MenuItem,
} from "@mui/material";

// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, addDoc } from "firebase/firestore";


const PersonalDataForm = () => {
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        nome: "",
        emailPessoal: "",
        emailProfissional: "",
        telefone: "",
        telefoneEmergencia: "",
        nomeContatoEmergencia: "",
        estadoCivil: "",
        grauInstrucao: "",
        nacionalidade: "",
        etniaCor: "",
        orgaoEmissor: "",
        numeroRG: "",
        estado: "",
        nomeBanco: "",
        numeroConta: "",
        agencia: "",
        cpf: "",
        cpfFile: null,
        rgFile: null,
        cursosFiles: [],
        comprovanteEndereco: null,
        cartaoCNPJ: null,
        pgrFile: null,
        pcmsFile: null,
    });

    const formatCPF = (cpf) => {
        // Remove todos os caracteres que não sejam números
        cpf = cpf.replace(/\D/g, "");
        // Aplica a máscara
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        return cpf;
    };


    const formatPhone = (phone) => {
        phone = phone.replace(/\D/g, ""); // Remove caracteres não numéricos
        phone = phone.replace(/(\d{2})(\d)/, "($1) $2"); // Aplica o formato (XX) XXXXX-XXXX
        phone = phone.replace(/(\d{5})(\d{4})$/, "$1-$2");
        return phone;
    };


// Manipulação de mudança para campos de texto
const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    let formattedValue = value;

    // Aplica a formatação adequada com base no campo
    if (name === 'cpf') {
        formattedValue = formatCPF(value);
    } else if (name === 'telefone' || name === 'telefoneEmergencia') {
        formattedValue = formatPhone(value);
    }

    setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue
    }));
};

    // Manipulação de mudança para campos de arquivo
    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: files.length > 1 ? Array.from(files) : files[0]
        }));
    };


    const handleMultipleFileChange = (event) => {
        const { files } = event.target;
        setFormData({ ...formData, cursosFiles: Array.from(files) });
    };


 



    const handleSubmit = async (event) => {
        event.preventDefault();

        const requiredFields = [
            'nome', 'cpf', 'emailPessoal', 'emailProfissional', 'telefone',
            'telefoneEmergencia', 'nomeContatoEmergencia', 'estadoCivil', 'grauInstrucao',
            'nacionalidade', 'etniaCor', 'orgaoEmissor', 'numeroRG', 'estado',
            'nomeBanco', 'numeroConta', 'agencia'
        ];

        for (const field of requiredFields) {
            if (!formData[field]) {
                setError(`O campo ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} é obrigatório.`);
                alert(`O campo ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} é obrigatório.`);
                return;
            }
        }

        const { cpfFile, rgFile, cursosFiles, comprovanteEndereco, cartaoCNPJ, pgrFile, pcmsFile, ...textualData } = formData;

        try {
            const data = new FormData();
            Object.keys(textualData).forEach(key => data.append(key, textualData[key]));

            const convertToBase64 = (file) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result.split(',')[1]);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });

            if (cpfFile) data.append('cpfFile', await convertToBase64(cpfFile));
            if (rgFile) data.append('rgFile', await convertToBase64(rgFile));
            if (cursosFiles && cursosFiles.length > 0) {
                for (const file of cursosFiles) {
                    data.append('cursosFiles[]', await convertToBase64(file));
                }
            }
            if (comprovanteEndereco) data.append('comprovanteEndereco', await convertToBase64(comprovanteEndereco));
            if (cartaoCNPJ) data.append('cartaoCNPJ', await convertToBase64(cartaoCNPJ));
            if (pgrFile) data.append('pgrFile', await convertToBase64(pgrFile));
            if (pcmsFile) data.append('pcmsFile', await convertToBase64(pcmsFile));

            const response = await axios.post('https://apachecadastropj.memt.com.br/cadastroparceirosapi/sendResponse.php', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log("Dados enviados com sucesso", response.data);

            // Aguarda 10 segundos antes de redirecionar
            setTimeout(() => {
                window.location.href = "/obrigado"; // Descomente quando quiser redirecionar
            }, 5000);

        } catch (error) {
            console.error("Erro ao enviar dados", error);
            alert("Ocorreu um erro ao enviar os dados. Tente novamente.");
        }
    };




    const handleReset = () => {
        setFormData({
            nome: "",
            emailPessoal: "",
            emailProfissional: "",
            telefone: "",
            telefoneEmergencia: "",
            nomeContatoEmergencia: "",
            estadoCivil: "",
            grauInstrucao: "",
            nacionalidade: "",
            etniaCor: "",
            orgaoEmissor: "",
            numeroRG: "",
            estado: "",
            nomeBanco: "",
            numeroConta: "",
            agencia: "",
            cpf: "",
            cpfFile: null,
            rgFile: null,
            cursosFiles: [],
            comprovanteEndereco: null,
            cartaoCNPJ: null,
            pgrFile: null,
            pcmsFile: null,
        });
    };

    const handleLoginClick = () => {
        window.location.href = '/login'; // Redireciona para a rota /login
    };


    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4, maxWidth: 600, mx: "auto" }}>


            <Typography variant="h4" gutterBottom align="center">
                Formulário de Dados Pessoais
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="small" // Define o tamanho do botão como pequeno
                onClick={handleLoginClick}
                sx={{ marginBottom: '10px' }}
            >
                Login
            </Button>

            <Grid container spacing={3}>
                {/* Campos de Texto */}
                <Grid item xs={12}>
                    <TextField
                        label="Nome Completo"
                        variant="outlined"
                        fullWidth
                        required
                        name="nome"
                        value={formData.nome}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        name="cpf"
                        value={formData.cpf}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="E-mail Pessoal"
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        name="emailPessoal"
                        value={formData.emailPessoal}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="E-mail Profissional"
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        name="emailProfissional"
                        value={formData.emailProfissional}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Telefone"
                        variant="outlined"
                        fullWidth
                        required
                        type="tel"
                        name="telefone"
                        value={formData.telefone}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Telefone para Emergência"
                        variant="outlined"
                        fullWidth
                        required
                        type="tel"
                        name="telefoneEmergencia"
                        value={formData.telefoneEmergencia}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nome do Contato para Emergência"
                        variant="outlined"
                        fullWidth
                        required
                        name="nomeContatoEmergencia"
                        value={formData.nomeContatoEmergencia}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        label="Estado Civil"
                        variant="outlined"
                        fullWidth
                        required
                        name="estadoCivil"
                        value={formData.estadoCivil}
                        onChange={handleInputChange}
                    >
                        {["Solteiro(a)", "Casado(a)", "Divorciado(a)", "Viúvo(a)"].map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        label="Grau de Instrução"
                        variant="outlined"
                        fullWidth
                        required
                        name="grauInstrucao"
                        value={formData.grauInstrucao}
                        onChange={handleInputChange}
                    >
                        {["Ensino Fundamental", "Ensino Médio", "Graduação", "Pós-graduação", "Mestrado", "Doutorado"].map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nacionalidade"
                        variant="outlined"
                        fullWidth
                        required
                        name="nacionalidade"
                        value={formData.nacionalidade}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        label="Etnia/Cor"
                        variant="outlined"
                        fullWidth
                        required
                        name="etniaCor"
                        value={formData.etniaCor}
                        onChange={handleInputChange}
                    >
                        {["Branca", "Negra", "Parda", "Amarela", "Indígena"].map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Número do RG"
                        variant="outlined"
                        fullWidth
                        required
                        name="numeroRG"
                        value={formData.numeroRG}
                        onChange={handleInputChange}
                    />
                </Grid>


                <Grid item xs={6}>
                    <TextField
                        label="Órgão Emissor"
                        variant="outlined"
                        fullWidth
                        required
                        name="orgaoEmissor"
                        value={formData.orgaoEmissor}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6} md={6}>
                    <TextField
                        select
                        label="Estado"
                        variant="outlined"
                        fullWidth
                        required
                        name="estado"
                        value={formData.estado}
                        onChange={handleInputChange}
                    >
                        {["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"].map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nome do Banco"
                        variant="outlined"
                        fullWidth
                        required
                        name="nomeBanco"
                        value={formData.nomeBanco}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Agência"
                        variant="outlined"
                        fullWidth
                        required
                        name="agencia"
                        value={formData.agencia}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Número da Conta"
                        variant="outlined"
                        fullWidth
                        required
                        name="numeroConta"
                        value={formData.numeroConta}
                        onChange={handleInputChange}
                    />
                </Grid>


                {/* Upload de Arquivo CPF */}
                <Grid item xs={6}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de CPF
                        <input type="file" hidden name="cpfFile" onChange={handleFileChange} accept="application/pdf" />
                    </Button>
                    {formData.cpfFile && <Typography variant="body2">{formData.cpfFile.name}</Typography>}
                </Grid>

                {/* Upload de Arquivo RG */}
                <Grid item xs={6}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de RG
                        <input type="file" hidden name="rgFile" onChange={handleFileChange} accept="application/pdf" />
                    </Button>
                    {formData.rgFile && <Typography variant="body2">{formData.rgFile.name}</Typography>}
                </Grid>

                {/* Upload de Arquivos Cursos */}
                <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                        PDF MULTÍPLOS
                        <input
                            type="file"
                            hidden
                            multiple
                            name="cursosFiles"
                            onChange={handleMultipleFileChange}
                            accept="application/pdf"
                        />
                    </Button>
                    {formData.cursosFiles.length > 0 && (
                        <Typography variant="body2">
                            {formData.cursosFiles.map(file => file.name).join(', ')}
                        </Typography>
                    )}
                </Grid>

                {/* Upload de Comprovante de Endereço */}
                <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de Comprovante de Endereço
                        <input
                            type="file"
                            hidden
                            name="comprovanteEndereco"
                            onChange={handleFileChange}
                            accept="application/pdf"

                        />
                    </Button>
                    {formData.comprovanteEndereco && <Typography variant="body2">{formData.comprovanteEndereco.name}</Typography>}
                </Grid>

                {/* Upload de Cartão CNPJ */}
                <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de Cartão CNPJ
                        <input
                            type="file"
                            hidden
                            name="cartaoCNPJ"
                            onChange={handleFileChange}
                            accept="application/pdf"

                        />
                    </Button>
                    {formData.cartaoCNPJ && <Typography variant="body2">{formData.cartaoCNPJ.name}</Typography>}
                </Grid>

                {/* Upload de PGR */}
                <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de PGR
                        <input
                            type="file"
                            hidden
                            name="pgrFile"
                            onChange={handleFileChange}
                            accept="application/pdf"

                        />
                    </Button>
                    {formData.pgrFile && <Typography variant="body2">{formData.pgrFile.name}</Typography>}
                </Grid>

                {/* Upload de PCMSO */}
                <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                        Upload de PCMSO
                        <input
                            type="file"
                            hidden
                            name="pcmsFile"
                            onChange={handleFileChange}
                            accept="application/pdf"

                        />
                    </Button>
                    {formData.pcmsFile && <Typography variant="body2">{formData.pcmsFile.name}</Typography>}
                </Grid>

                {/* Botões */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" type="submit">
                        Enviar
                    </Button>
                    <Button variant="outlined" onClick={handleReset}>
                        Limpar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PersonalDataForm;
