import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';

const CreateUser = () => {
  const [nome, setNome] = useState(''); // Estado para nome
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleCreateUser = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('https://apachecadastropj.memt.com.br/cadastroparceirosapi/create_user.php', {
        nome,
        email,
        password,
      });

      if (response.data.success) {
        setSuccess('Usuário criado com sucesso!');

        setTimeout(() => {
          window.location.href = '/login'
        }, 3500);
        
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError('Erro ao criar usuário.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '10%',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Criar Usuário
        </Typography>
        <form onSubmit={handleCreateUser}>
          <TextField
            label="Nome Completo"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="success">{success}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Criar Usuário
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default CreateUser;
