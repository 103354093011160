import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate do React Router

const Login = () => {
  // Estado para email, senha e mensagens de erro
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Hook para navegação
  const navigate = useNavigate(); // Inicialize o hook de navegação

 // Função para lidar com o login
const handleLogin = async (event) => {
  event.preventDefault();
  setError('');

  try {
    const response = await fetch('https://apachecadastropj.memt.com.br/cadastroparceirosapi/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const data = await response.json();

    if (data.success) {
      // Armazena as informações do usuário no sessionStorage
      sessionStorage.setItem('user', JSON.stringify({ 
        id: data.id, 
        nome: data.nome, 
        email: data.email 
      }));

      // Redirecionar para a página /view após o login bem-sucedido
      console.log('Login efetuado com sucesso!');
      window.location.href = '/view';
    } else {
      setError(data.message);
    }
  } catch (err) {
    setError('Erro ao tentar se conectar ao servidor.');
  }
};

  

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '10%',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Entrar
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
