import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Form from './Pages/Formulario/Form';
import View from './Pages/Viewer/Viewer';
import Login from './Pages/Login/Login';
import Registrar from './Pages/Registrar/Registrar'
import ThankYouPage from './Pages/Thank-you/Thank-you';
import ProtectedRoute from './Hook/ProtectedRoute/ProtectedRoute';
import CheckVisibility from './Hook/CheckVisibility/CheckVisibility'; // Importe o novo componente
import './App.css';

function App() {
  return (
    <BrowserRouter>

        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/view" element={
            <ProtectedRoute>
              <View />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login />} />
         
          <Route path="/obrigado" element={<ThankYouPage />} />

          <Route path="/registrar" element={
            <CheckVisibility>
              <Registrar />
            </CheckVisibility>
          } />


        </Routes>

    </BrowserRouter>
  );
}

export default App;
