import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Verifica se o usuário está autenticado no sessionStorage
  const user = JSON.parse(sessionStorage.getItem('user'));

  // Se o usuário não estiver autenticado, redirecione para /login
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Caso contrário, renderize o componente filho
  return children;
};

export default ProtectedRoute;
