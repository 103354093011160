import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const ThankYouPage = () => {
    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundColor: 'rgb(11, 16, 62)', // Cor de fundo
                color: 'white', // Cor do texto para contraste
                padding: 2 // Adiciona algum espaçamento interno
            }}
        >
            <Typography variant="h4" gutterBottom>
                Obrigado!
            </Typography>
            <Link href="/" variant="body1" sx={{ mb: 2, color: 'white' }}>
                Responder formulário novamente
            </Link>
            <Link href="https://www.memt.com.br" variant="body1" sx={{ color: 'white' }}>
                Acesse nosso site
            </Link>
        </Box>
    );
};

export default ThankYouPage;
