import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Modal,
  IconButton,
  Divider,
  Grid,
  TablePagination,
  TextField,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { lighten } from '@mui/material/styles';
import axios from 'axios';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOnIcon from '@mui/icons-material/ToggleOn'; // Ícone para ativar
import ToggleOffIcon from '@mui/icons-material/ToggleOff'; // Ícone para desativar

const ViewData = () => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isVisible, setIsVisible] = useState(true);
  const [dataAdmissao, setDataAdmissao] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apachecadastropj.memt.com.br/cadastroparceirosapi/getData.php');
        // console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do banco de dados:', error);
        setData([]);
      }
    };

    const fetchVisibility = async () => {
      try {
        const response = await axios.get('https://apachecadastropj.memt.com.br/cadastroparceirosapi/getVisibility.php');
        if (response.data && typeof response.data.isVisible === 'boolean') {
          setIsVisible(response.data.isVisible);
        } else {
          console.error('Formato da resposta de visibilidade inesperado:', response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar visibilidade:', error);
      }
    };

    fetchData();
    fetchVisibility();
  }, []);

  const handleToggleVisibility = async () => {
    try {
      const response = await axios.post('https://apachecadastropj.memt.com.br/cadastroparceirosapi/toggleVisibility.php', {
        isVisible: !isVisible,
      });
      setIsVisible(response.data.isVisible);
    } catch (error) {
      console.error('Erro ao alterar visibilidade:', error);
    }
  };

  const handleOpenModal = (item) => {
    setSelectedData(item);
    setDataAdmissao(item.admissao || ''); // Preencher o campo com a data de admissão existente
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedData(null);
    setDataAdmissao(''); // Limpar o campo ao fechar
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSaveDataAdmissao = async () => {
    const formattedDate = new Date(dataAdmissao).toISOString().slice(0, 19).replace('T', ' '); // Formato YYYY-MM-DD HH:MM:SS
    // console.log('Dados a serem enviados:', { id: selectedData.id, admissao: formattedDate });
    try {
        const response = await axios.post('https://apachecadastropj.memt.com.br/cadastroparceirosapi/saveDataAdmissao.php', {
            id: selectedData.id,
            admissao: formattedDate,
        });

        if (response.data.success) {
            setData(data.map(item => item.id === selectedData.id ? { ...item, admissao: formattedDate } : item));
            handleCloseModal();
        } else {
            console.error('Erro ao salvar:', response.data.message);
        }
    } catch (error) {
        console.error('Erro ao enviar a requisição:', error);
    }
};


  // const exportToExcel = () => {
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Data');
  //   XLSX.writeFile(wb, 'data.xlsx');
  // };


  const exportToExcel = () => {
    // Cria uma nova array com os dados filtrados
    const filteredData = data.map(item => ({
        nome: item.nome,
        emailPessoal: item.emailPessoal,
        emailProfissional: item.emailProfissional,
        telefone: item.telefone,
        cpf: item.cpf,
        nomeContatoEmergencia: item.nomeContatoEmergencia,
        telefoneEmergencia: item.telefoneEmergencia,
        estado: item.estado,
        estadoCivil: item.estadoCivil,
        etinia: item.etniaCor,
        grauInstrucao: item.grauInstrucao,
        nacionalidade: item.nacionalidade,
        nomeBanco: item.nomeBanco,
        numeroConta: item.numeroConta,
        numeroRG: item.numeroRG,
        orgaoEmissor: item.orgaoEmissor,
        dataExame: item.admissao,

    }));

    // Cria a planilha e o livro do Excel
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    // Obtém a data atual no formato desejado (por exemplo, YYYY-MM-DD)
    const currentDate = new Date().toISOString().slice(0, 10);

    // Define o nome do arquivo com a data atual
    const fileName = `cadastroparceiro_${currentDate}.xlsx`;

    // Salva o arquivo
    XLSX.writeFile(wb, fileName);
};



  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Lista de Dados e Arquivos PDF
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<GetAppIcon />}
        onClick={exportToExcel}
        sx={{ mb: 2 }}
      >
        Exportar para Excel
      </Button>

      <IconButton onClick={handleToggleVisibility} sx={{ mb: 2 }}>
        {isVisible ? <ToggleOnIcon color="success" /> : <ToggleOffIcon color="error" />}
      </IconButton>
      {/* <Typography variant="body1" gutterBottom>
        {isVisible ? 'Visibilidade: Ativo' : 'Visibilidade: Inativo'}
      </Typography> */}

      <Typography variant="h6" gutterBottom>
        Dados do Cadastro
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Email Pessoal</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Email Profissional</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Telefone</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome do Contato de Emergência</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Telefone de Emergência</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Estado</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Estado Civil</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Data do Exame</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
              <TableRow key={index} sx={{ '&:hover': { backgroundColor: lighten('#cfcfcf', 0.4) } }}>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.emailPessoal}</TableCell>
                <TableCell>{item.emailProfissional}</TableCell>
                <TableCell>{item.telefone}</TableCell>
                <TableCell>{item.nomeContatoEmergencia}</TableCell>
                <TableCell>{item.telefoneEmergencia}</TableCell>
                <TableCell>{item.estado}</TableCell>
                <TableCell>{item.estadoCivil}</TableCell>
                <TableCell>{item.admissao ? new Date(item.admissao).toLocaleDateString('pt-BR') : ''}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenModal(item)}>
                    <MoreHorizIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {selectedData && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                Detalhes do Item
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography><strong>Nome:</strong> {selectedData.nome}</Typography>
              <Typography><strong>Email Pessoal:</strong> {selectedData.emailPessoal}</Typography>
              <Typography><strong>Email Profissional:</strong> {selectedData.emailProfissional}</Typography>
              <Typography><strong>Estado:</strong> {selectedData.estado}</Typography>
              <Typography><strong>Estado Civil:</strong> {selectedData.estadoCivil}</Typography>
              <Typography><strong>Etnia/Cor:</strong> {selectedData.etniaCor}</Typography>
              <Typography><strong>Grau de Instrução:</strong> {selectedData.grauInstrucao}</Typography>
              <Typography><strong>Nacionalidade:</strong> {selectedData.nacionalidade}</Typography>
              <Typography><strong>Nome do Banco:</strong> {selectedData.nomeBanco}</Typography>
              <Typography><strong>Nome do Contato de Emergência:</strong> {selectedData.nomeContatoEmergencia}</Typography>
              <Typography><strong>Número da Conta:</strong> {selectedData.numeroConta}</Typography>
              <Typography><strong>Número do RG:</strong> {selectedData.numeroRG}</Typography>
              <Typography><strong>CPF:</strong> {selectedData.cpf}</Typography>
              <Typography><strong>Órgão Emissor:</strong> {selectedData.orgaoEmissor}</Typography>
              <Typography><strong>Telefone:</strong> {selectedData.telefone}</Typography>
              <Typography><strong>Telefone de Emergência:</strong> {selectedData.telefoneEmergencia}</Typography>
              <Divider sx={{ my: 2 }} />

              <TextField
                label="Data do Exame"
                type="date"
                value={dataAdmissao}
                onChange={(e) => setDataAdmissao(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: 2 }}
                disabled={!!selectedData.admissao} // Desativa se houver data de admissão
              />

              <Button variant="contained" color="primary" onClick={handleSaveDataAdmissao}>
                Salvar Data do Exame
              </Button>

              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Documentos PDF</Typography>

              <Grid container spacing={2} mt={2}>
                {selectedData ? (
                  <>
                    {[ 
                      { nome: 'CPF', base64: selectedData.cpfFile },
                      { nome: 'RG', base64: selectedData.rgFile },
                      ...(Array.isArray(selectedData.cursosFiles) ? selectedData.cursosFiles : []).map((file, index) => ({
                        nome: `Curso ${index + 1}`,
                        base64: file,
                      })),
                      { nome: 'Comprovante de Endereço', base64: selectedData.comprovanteEndereco },
                      { nome: 'Cartão CNPJ', base64: selectedData.cartaoCNPJ },
                      { nome: 'PGR', base64: selectedData.pgrFile },
                      { nome: 'PCMS', base64: selectedData.pcmsFile },
                    ].filter(file => file.base64).map((file, index) => (
                      <Grid item xs={12} key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body1" sx={{ flexGrow: 1 }}>{file.nome}</Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = `data:application/pdf;base64,${file.base64}`;
                              link.download = file.nome;
                              link.click();
                            }}
                          >
                            Download
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<VisibilityIcon />}
                            sx={{ ml: 1 }}
                            onClick={() => {
                              const byteCharacters = atob(file.base64);
                              const byteNumbers = new Array(byteCharacters.length);
                              for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                              }
                              const byteArray = new Uint8Array(byteNumbers);
                              const blob = new Blob([byteArray], { type: 'application/pdf' });
                              const url = URL.createObjectURL(blob);
                              window.open(url);
                            }}
                          >
                            Visualizar
                          </Button>
                        </Box>
                      </Grid>
                    ))}

                    {selectedData.cursosFiles === null && (
                      <Typography variant="body1">Vazio</Typography>
                    )}

                    {(!selectedData.cpfFile && !selectedData.rgFile &&
                      (selectedData.cursosFiles === null || (Array.isArray(selectedData.cursosFiles) && selectedData.cursosFiles.length === 0)) &&
                      !selectedData.comprovanteEndereco &&
                      !selectedData.cartaoCNPJ &&
                      !selectedData.pgrFile &&
                      !selectedData.pcmsFile) && (
                        <Typography variant="body1">Nenhum arquivo PDF disponível.</Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body1">Nenhum dado selecionado.</Typography>
                )}
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewData;
