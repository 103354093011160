import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const CheckVisibility = ({ children }) => {
  const [isVisible, setIsVisible] = useState(null); // Inicializa como null para indicar carregamento

  useEffect(() => {
    const fetchVisibility = async () => {
      try {
        const response = await axios.get('https://apachecadastropj.memt.com.br/cadastroparceirosapi/getVisibility.php');
        setIsVisible(response.data.isVisible);
      } catch (error) {
        console.error('Erro ao buscar visibilidade:', error);
        setIsVisible(false); // Considera false em caso de erro
      }
    };

    fetchVisibility();
  }, []);

  if (isVisible === null) {
    return <div>Loading...</div>; // Renderiza um loading enquanto verifica
  }

  // Se isVisible for false, redireciona para /login
  if (!isVisible) {
    return <Navigate to="/login" />;
  }

  // Se isVisible for true, renderiza o componente filho
  return children;
};

export default CheckVisibility;
